import { dev } from '$app/environment'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import * as Sentry from '@sentry/sveltekit'

const ENVIRONMENT = dev ? 'development' : import.meta.env.VERCEL_ENV

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()],
	environment: ENVIRONMENT,
	enabled: ENVIRONMENT !== 'development',
})

export const handleError = Sentry.handleErrorWithSentry()
